.header-1 {
  @apply text-balance font-oswald text-[48px] font-bold uppercase leading-[110%] lg:text-[112px] hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.header-2 {
  @apply text-balance font-oswald text-[32px] font-bold uppercase leading-[110%] lg:text-[48px] hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.header-3 {
  @apply text-balance font-oswald text-[28px] font-bold uppercase leading-[110%] lg:text-[32px] hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.header-4 {
  @apply text-balance font-oswald text-lg font-bold uppercase leading-[110%] lg:text-xl hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.header-5 {
  @apply text-balance font-oswald text-base font-bold uppercase leading-[110%] lg:text-lg hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.header-6 {
  @apply text-balance font-oswald text-base font-bold uppercase leading-[110%] lg:text-base hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.body {
  @apply text-balance font-calluna text-base font-light leading-[140%] hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.body-l {
  @apply text-balance font-calluna text-lg font-bold leading-[140%] hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.body-xl {
  @apply text-balance font-calluna text-[22px] font-light leading-[140%] hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.jsc {
  .body,
  .body-l,
  .body-xl {
    @apply font-lato hyphens-auto;
    @media (max-width: 768px) {
      text-wrap: wrap;
    }
  }
}

.link {
  @apply font-roboto text-base font-light underline underline-offset-2 hyphens-auto;
  text-transform: none!important;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}

.support {
  @apply font-roboto text-base font-light hyphens-auto;
  @media (max-width: 768px) {
    text-wrap: wrap;
  }
}
